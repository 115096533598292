<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <LoaderPage v-if="isLoading"/>
            <template v-else>
                <!-- Search -->
                <div class="uk-flex uk-flex-between uk-margin">
                    <div class="uk-flex uk-flex-left">
                        <form @submit.prevent="filterSearch" class="uk-margin-remove">
                            <div class="uk-inline">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                                <input 
                                    :disabled="reorderMode == true ? reorderMode : false"
                                    class="uk-input" 
                                    type="search" 
                                    aria-label="Search reference" 
                                    placeholder="Search reference.."
                                    v-model="meta.name"
                                />
                            </div>
                        </form>
                        <div class="uk-margin-left">
                            <button 
                                v-if="reorderMode == false"
                                type="button" 
                                class="uk-button uk-button-primary uk-width-1-1 button-reorder"
                                @click="enableReorderMode({ value: true })"
                            >
                            <img :src="`${images}/fluent_arrow-sort-filled.svg`" alt="Reorder">
                            Reorder</button>
                        </div>
                    </div>
                    <div class="uk-flex uk-flex-right">
                        <button v-if="reorderMode == false" class="uk-button uk-button-primary uk-border-rounded" @click="showModalCreateNewVacancyReference">
                            Create
                        </button>
                        <button v-if="reorderMode" class="uk-button uk-button-default uk-border-rounded uk-margin-small-right" @click="cancelReorderVacancyReferenceList">
                            Cancel
                        </button>
                        <button 
                            v-if="reorderMode" 
                            class="uk-button uk-border-rounded" 
                            :class="[onMoveVacancyItem === true ? 'uk-button-default' : 'uk-button-primary']"
                            :disabled="onMoveVacancyItem === true"
                            @click="saveReorder">
                            Save Changes
                        </button>
                    </div>
                </div>
                <!-- End search -->

                <!-- Vacancy content list -->
                <div class="uk-margin-top uk-card uk-card-default">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-striped uk-table-middle">
                            <thead>
                                <tr>
                                    <th class="uk-table-shrink">No</th>
                                    <th>Vacancy Reference</th>
                                    <!-- Order Information -->
                                    <!-- <th>Order</th> -->
                                    <th v-if="reorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])" class="uk-width-small uk-text-center"></th>
                                    <th v-else-if="!reorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])" class="uk-width-small uk-text-center">Action</th>
                                </tr>
                            </thead>
                            <loading-table v-if="tableLoading" :colspan="3" />
                            <template v-else>
                                <template v-if="reorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])">
                                    <draggable
                                        v-if="vacancyRefData.docs.length > 0"
                                        v-model="vacancyRefData.docs"
                                        tag="tbody"
                                        @change="onDrag"
                                    >
                                        <tr 
                                            v-for="(vacancy, index) in vacancyRefData.docs" 
                                            :key="index" 
                                            :draggable="reorderMode" 
                                            :class="[reorderMode === true ? 'box' : '']"
                                        >
                                            <td class="uk-table-shrink">
                                                {{ autoIncrementNumberPagination({ 
                                                    pagingCounter: vacancyRefData.pagingCounter,
                                                    index 
                                                }) }}
                                            </td>
                                            <td>{{ vacancy.name || '-' }}</td>
                                            <!-- Order Information -->
                                            <!-- <td>{{ vacancy.order }}</td> -->
                                            <td class="uk-width-small uk-text-center">
                                                <img :src="`${images}/arrow-bidirectional-up-down-12-filled.svg`" alt="Drag drop icon" class="drag-drop-icon">
                                            </td>
                                        </tr>
                                    </draggable>
                                    <empty-table v-else :colspan="3" />
                                </template>
                                <template v-else-if="!reorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])">
                                    <tbody v-if="vacancyRefData.docs.length > 0">
                                        <tr 
                                            v-for="(vacancy, index) in vacancyRefData.docs" 
                                            :key="index" 
                                            :draggable="reorderMode" 
                                            :class="[reorderMode === true ? 'box' : '']"
                                        >
                                            <td class="uk-table-shrink">
                                                {{ autoIncrementNumberPagination({ 
                                                    pagingCounter: vacancyRefData.pagingCounter,
                                                    index 
                                                }) }}
                                            </td>
                                            <td>{{ vacancy.name || '-' }}</td>
                                            <!-- Order Information -->
                                            <!-- <td>{{ vacancy.order }}</td> -->
                                            <td v-if="consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])" class="uk-width-small uk-text-center">
                                                <button 
                                                    type="button" 
                                                    class="uk-button uk-button-small uk-button-default"
                                                >Actions</button>
                                                <div uk-dropdown="mode: click">
                                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                        <li><a href="#" @click="editVacancy({ data: vacancy })">Edit</a></li>
                                                        <li class="uk-nav-divider"></li>
                                                        <li><a href="#" @click="deleteVacancy({ data: vacancy })">Delete</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <empty-table v-else :colspan="3" />
                                </template>
                            </template>
                        </table>
                    </div>
                    <pagination
                        :total-data="vacancyRefData.totalDocs"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    />
                </div>
                <!-- End Vacancy content list -->
            </template>
            <!-- Vacancy feature modal box -->
            <div id="create-or-edit-vacancy-modal-box" class="create-or-edit-vacancy-modal-box uk-flex-top" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">
                            {{ createOrEditVacancyType === 'create' ? 'Create Vacancy Reference' : 'Edit Vacancy Reference' }}
                        </h2>
                    </div>
                    <form class="uk-form-stacked" @submit.prevent="createOrEditVacancy">
                        <div class="uk-modal-body">
                            <div class="uk-margin">
                                <label for="vacancyName" class="uk-form-label">Vacancy Reference Name <span class="uk-text-danger">*</span></label>
                                <div class="uk-form-controls">
                                    <input
                                        id="vacancyName"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        v-model="selectedVacancyData.name"
                                        :maxlength="maxCharactersLength"
                                        autofocus
                                    >
                                </div>
                                <span class="uk-text-small">{{selectedVacancyData.name.length}}/{{maxCharactersLength}} Characters</span>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-text-right">
                            <button class="uk-button uk-button-default uk-modal-close button-blue-transparent" type="button">Cancel</button>
                            <button
                                v-if="createOrEditVacancySaveButtonLoading"
                                type="button"
                                class="uk-button uk-button-default uk-border-rounded"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button 
                                v-else
                                type="submit"
                                class="uk-button"
                                :class="[disabledCreateOrEditVacancyRefSaveButton === true ? 'uk-button-default' : 'uk-button-primary']" 
                                :disabled="disabledCreateOrEditVacancyRefSaveButton === true"
                            >Save</button>
                        </div>
                    </form>
                </div>
            </div>

            <div id="confirmation-delete-vacancy-modal-box" class="confirmation-delete-vacancy-modal-box uk-flex-top" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" @click="resetSelectedVacancyData" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">Are you sure to delete this data ?</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p>Vacancy Reference: {{ selectedVacancyData.name || '-' }}</p>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button
                            v-if="deleteVacancyButtonLoading"
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded"
                            disabled
                            >
                        <span uk-spinner="ratio: 0.5"></span>
                        <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <button
                            v-else
                            type="submit"
                            class="uk-button uk-border-rounded"
                            :class="[disabledDeleteVacancyButton === true ? 'uk-button uk-button-default' : 'uk-button uk-button-danger']"
                            :disabled="disabledDeleteVacancyButton === true"
                            @click="doDeleteVacancyReference"
                            >Delete</button>
                    </div>
                </div>
            </div>
        </template>
        
        <NoInternetConnection v-else />
    </div>
</template>
<script>
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
import formatter from '@/utils/formatter';

export default {
    name: 'VacancyReference',
    components: {
        draggable,
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
    },
    data(){
        return {
            images: PREFIX_IMAGE,
            user_cred: getUserLogin(),
            internetConnection: true,
            isLoading: false,
            tableLoading: false,
            vacancyRefData: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0,
            },
            meta: {
                limit: 10,
                page: 1,
                name: ''
            },
            reorderMode: false,
            onMoveVacancyItem: true,
            payloadReorderVacancyList: {
                paging_counter: 0,
                vacancy_reference_ids: []
            },
            selectedVacancyData: {
                id: null,
                name: '',
                order: null
            },
            tempSelectedVacancyData: {
                id: null,
                name: '',
                order: null
            },
            createOrEditVacancyType: 'create',
            maxCharactersLength: 60,
            createOrEditVacancySaveButtonLoading: false,
            deleteVacancyButtonLoading: false
        };
    },
    async mounted(){
        try {
            if (!navigator.onLine) return this.internetConnection = false;
            this.isLoading=true;
            await this.setVacancyReferenceData();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-or-edit-vacancy-modal-box')) window.UIkit.modal('.create-or-edit-vacancy-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-delete-vacancy-modal-box')) window.UIkit.modal('.confirmation-delete-vacancy-modal-box').$destroy(true);
    },
    computed: {
        disabledCreateOrEditVacancyRefSaveButton() {
            let disabled = true;
            if (this.createOrEditVacancyType === 'create'){
                if (this.selectedVacancyData.name === '') {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.createOrEditVacancyType === 'edit') {
                if (this.selectedVacancyData.id === null ||
                    this.selectedVacancyData.name === '' || 
                    this.removeSpaceFromString({string: this.selectedVacancyData.name}).toLowerCase() === this.removeSpaceFromString({string: this.tempSelectedVacancyData.name}).toLowerCase())
                {
                    disabled = true;
                } else {
                    disabled = false;
                }
            }
            return disabled;
        },
        disabledDeleteVacancyButton() {
            let disabled = true;
            if (this.selectedVacancyData.id === null || this.selectedVacancyData.name === ''){
                disabled = true;
            } else {
                disabled = false;
            }
            return disabled;
        }
    },
    watch: {
        async meta() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                await this.setVacancyReferenceData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        'selectedVacancyData.name'(val) {
            this.name = val.replace(/[^ \w]/g, "").trim();
        },
    },
    methods: {
        ...mapActions({
            createVacancyRef: 'vacancyRefV4/createVacancyRef',
            getVacancyReferenceList: 'vacancyRefV4/getVacancyReferenceList',
            updateVacancyReference: 'vacancyRefV4/updateVacancyReference',
            getVacancyReferenceDetail: 'vacancyRefV4/getVacancyReferenceDetail',
            deleteVacancyReference: 'vacancyRefV4/deleteVacancyReference',
            reorderVacancyRefList: 'vacancyRefV4/reorderVacancyRefList'
        }),
        async setVacancyReferenceData(){
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                const response = await this.getVacancyReferenceList(this.meta);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    this.vacancyRefData = response.data.result;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        changeLimit(e) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async filterSearch(){
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                this.meta.limit = 10;
                this.meta.page = 1;
                await this.setVacancyReferenceData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async showModalCreateNewVacancyReference(){
            try {
                if(!navigator.onLine) return this.internetConnection = false;
                this.resetSelectedVacancyData();
                this.createOrEditVacancyType = 'create';
                await window.UIkit.modal('#create-or-edit-vacancy-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showModalEditVacancyReference(data){
            try {
                if(!navigator.onLine) return this.internetConnection = false;
                this.resetSelectedVacancyData();
                this.createOrEditVacancyType = 'edit';
                if(data){
                    this.fillSelectedVacancyData({id: data._id, name: data.name, order: data.order});
                    this.fillTempSelectedVacancyData({id: data._id, name: data.name, order: data.order});
                }
                await window.UIkit.modal('#create-or-edit-vacancy-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showModalDeleteVacancy(data){
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.resetSelectedVacancyData();
                if(data){
                    this.fillSelectedVacancyData({id: data._id, name: data.name, order: data.order});
                }
                await window.UIkit.modal('#confirmation-delete-vacancy-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        saveReorder() {
            this.saveReorderVacancyReferenceList(this.payloadReorderVacancyList);
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        onDrag(event) {
            this.onMoveVacancyItem = false;
            if (event && event.moved) {
                this.resetPayloadReorderVacancyList();
                this.fillPayloadReorderVacancyList({ pagingCounter: this.vacancyRefData.pagingCounter, vacancyList: this.vacancyRefData.docs});
            }
        },
        autoIncrementNumberPagination({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        },
        editVacancy({ data }) {
            this.hideDropdown();
            this.showModalEditVacancyReference(data);
        },
        deleteVacancy({ data }) {
            this.hideDropdown();
            this.showModalDeleteVacancy(data);
        },
        resetSelectedVacancyData(){
            this.selectedVacancyData.id = null;
            this.selectedVacancyData.name = '';
            this.selectedVacancyData.order = null;
        },
        fillSelectedVacancyData({id, name, order}){
            this.selectedVacancyData.id = id;
            this.selectedVacancyData.name = name;
            this.selectedVacancyData.order = order;
        },
        fillTempSelectedVacancyData({id, name, order}){
            this.tempSelectedVacancyData.id = id;
            this.tempSelectedVacancyData.name = name;
            this.tempSelectedVacancyData.order = order;
        },
        async enableReorderMode({value}){
            this.onMoveVacancyItem = value;
            this.resetPayloadReorderVacancyList();
            this.reorderMode = value;
            if (value == false) {
                this.tableLoading = true;
                await this.setVacancyReferenceData();
            }
        },
        async cancelVacancyReferenceList(){
            try {
                if(!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                await this.setVacancyReferenceData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
                this.reorderMode = false;
            }
        },
        cancelReorderVacancyReferenceList(){
            this.onMoveVacancyItem = true;
            this.resetPayloadReorderVacancyList();
            this.hideDropdown();
            this.cancelVacancyReferenceList();
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
        },
        async createOrEditVacancy() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                if (this.createOrEditVacancyType === 'create') {
                    await this.doCreateVacancyReference();
                } else if(this.createOrEditVacancyType === 'edit'){
                    await this.doEditVacancyReference();
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCreateVacancyReference(){
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.createOrEditVacancySaveButtonLoading = true;
                const payloads = {name: this.selectedVacancyData.name};
                const response = await this.createVacancyRef(payloads);
                if (response && response.data && response.data.status === 'OK') {
                    this.resetSelectedVacancyData();
                    await window.UIkit.modal('#create-or-edit-vacancy-modal-box').hide();
                    this.tableLoading = true;
                    await this.setVacancyReferenceData();
                    notificationSuccess('Vacancy Reference created successfully');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.createOrEditVacancySaveButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async doEditVacancyReference(){
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.createOrEditVacancySaveButtonLoading = true;
                const payloads = {
                    id: this.selectedVacancyData.id,
                    name: this.selectedVacancyData.name
                };
                const response = await this.updateVacancyReference(payloads);
                if (response && response.data && response.data.status === 'OK') {
                    this.resetSelectedVacancyData();
                    await window.UIkit.modal('#create-or-edit-vacancy-modal-box').hide();
                    this.tableLoading = true;
                    await this.setVacancyReferenceData();
                    notificationSuccess('Vacancy Reference updated successfully');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.createOrEditVacancySaveButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async doDeleteVacancyReference(){
            try {
                if(!navigator.onLine) return this.internetConnection = false;
                this.deleteVacancyButtonLoading = true;
                const response = await this.deleteVacancyReference(this.selectedVacancyData.id);
                if (response && response.data && response.data.status === 'OK'){
                    this.resetSelectedVacancyData();
                    await window.UIkit.modal('#confirmation-delete-vacancy-modal-box').hide();
                    this.tableLoading = true;
                    await this.setVacancyReferenceData();
                    notificationSuccess('Vacancy Reference deleted successfully');
                }
            } catch (error) {
                await window.UIkit.modal('#confirmation-delete-vacancy-modal-box').hide();
                notificationDanger(error);
            } finally {
                this.deleteVacancyButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async saveReorderVacancyReferenceList(payload){
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                const response = await this.reorderVacancyRefList(payload);
                if (response && response.status === 'OK') {
                    this.tableLoading = true;
                    await this.setVacancyReferenceData();
                    notificationSuccess('Vacancy Reference list reordered successfully');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
                this.reorderMode = false;
            }
        },
        removeSpaceFromString({ string }) {
            return string.replace(/\s/g, '');
        },
        resetPayloadReorderVacancyList() {
            this.payloadReorderVacancyList.paging_counter = 0;
            this.payloadReorderVacancyList.vacancy_reference_ids = [];
        },
        fillPayloadReorderVacancyList({pagingCounter, vacancyList}) {
            this.payloadReorderVacancyList.paging_counter = pagingCounter;
            if (vacancyList && vacancyList.length > 0) {
                const distinctVacancyReferenceIds = [...new Set(vacancyList.map(item => String(item._id)))];
                this.payloadReorderVacancyList.vacancy_reference_ids = distinctVacancyReferenceIds;
            } else {
                this.payloadReorderVacancyList.vacancy_reference_ids = [];
            }
        },
    }
};
</script>
<style scoped>
.button-blue-transparent {
    color: #1e87f0;
    border: 1px solid #1e87f0;
    background-color: transparent;
    margin-right: .5rem;
}
.button-reorder {
    background-color: transparent;
    color: #1e87f0;
    padding: 0px;
}
.button-reorder:hover {
    color: #0f66bd;
}
</style>
